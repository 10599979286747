import { createContext, useContext, useEffect, useState } from 'react';

import { isNil } from 'ramda';

import { useQueryClient } from '@tanstack/react-query';

import { setCookie } from 'nookies';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { tradeInApi } from '@/config/api';

import FullScreenSpinner from '@/components/full-screen-spinner';

type SessionData = {
  sessionToken?: string;
  redirectUrl?: string;
};

type SessionContext = {
  sessionToken?: string;
  redirectUrl?: string;

  saveSessionData: (data: SessionData) => void;
  clearSession: () => void;
};

const SessionAuthContext = createContext<SessionContext>({} as SessionContext);

export const SessionAuthContextProvider: React.FCWC = ({ children }) => {
  const queryClient = useQueryClient();

  const localStorage = useLocalStorage();
  const sessionDataStorage = localStorage?.getItem(STORAGE_KEYS.SESSION_DATA);

  const [sessionData, setSessionData] = useState<SessionData>(() => {
    try {
      return sessionDataStorage;
    } catch (_) {
      return {};
    }
  });

  const [interceptorId, setInterceptorId] = useState<number>();

  const saveSessionData = (data: SessionData) => {
    setSessionData((prev) => ({ ...prev, ...data }));

    if (data.sessionToken) setCookie(null, '__doji_session', data.sessionToken, { path: '/' });
    localStorage?.setItem(STORAGE_KEYS.SESSION_DATA, data);
  };

  const clearSession = () => {
    queryClient.resetQueries();
    tradeInApi.interceptors.request.clear();
    setSessionData({});
  };

  useEffect(() => {
    if (interceptorId) tradeInApi.interceptors.request.clear();

    const interceptor = tradeInApi.interceptors.request.use((req) => {
      if (sessionData?.sessionToken) req.headers['Authorization'] = `Bearer ${sessionData?.sessionToken}`;

      return req;
    });

    setInterceptorId(interceptor);
  }, [sessionData?.sessionToken]);

  const values = {
    sessionToken: sessionData?.sessionToken,
    redirectUrl: sessionData?.redirectUrl,
    clearSession,
    saveSessionData,
  };

  if (isNil(interceptorId)) return <FullScreenSpinner isVisible />;

  return <SessionAuthContext.Provider value={values}>{children}</SessionAuthContext.Provider>;
};

export const useSessionAuth = () => useContext(SessionAuthContext);
